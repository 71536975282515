import React from 'react';
import { Link } from 'react-router-dom';

import ContactIcons from '../Contact/ContactIcons';

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        <img src={`${PUBLIC_URL}/images/aritra-ghosh-pfp.png`} alt="Aritra Ghosh" />
      </Link>
      <header>
        <h2>Aritra Ghosh</h2>
        <p><a href="mailto:hello@aritra-ghosh.co">hello@aritra-ghosh.co</a></p>
      </header>
    </section>

    <section className="blurb">
      <h2>About</h2>
      <p>MoE&apos; IIC | SIH&apos;23 Winner | Ex-Intern at @CareEdge Group | 5x Hackathon Winner |
        Artificial Intelligence | Machine Learning |
        Fullstack Web Development | TSoC&apos;23 |
        Pre-Final Year CS Undergrad (IEM&apos;25)
      </p>
      <ul className="actions">
        <li>
          {!window.location.pathname.includes('/resume') ? <Link to="https://drive.google.com/file/d/1gx6I5k9BSYbt90VmOr1Ipxsymk-BWeIG/view" className="button">View Resume</Link> : <Link to="https://drive.google.com/file/d/1gx6I5k9BSYbt90VmOr1Ipxsymk-BWeIG/view" className="button">Download Resume</Link>}
        </li>
      </ul>
    </section>

    <section id="footer">
      <ContactIcons />
      <p className="copyright">&copy; Aritra Ghosh <Link to="https://aritraghosh.xyz">aritraghosh.xyz</Link>.</p>
    </section>
  </section>
);

export default SideBar;
